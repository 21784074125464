<template>
    <div>
        <div class="col-md-12">
            <div class="row" style="margin-top:20px">
                <div class="col-md-6">
                    <h3 class="page-header">
                        <i class="fa fa fa-user animated bounceInDown show-info"></i>
                        Employee Ledger
                    </h3>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-body" style="background: none;">
                    <table class="table table-bordered table-hover table-striped display" id="tblmanual">
                        <thead class="table-font" style="background: #475c78;color: #fff;">
                            <th class="visible-lg">#</th>
                            <th>Party name</th>
                            <th>Last Transaction</th>
                            <th>Balance</th>
                            <th>Detail</th>
                            <!-- <th>Balance</th> -->
                        </thead>
                        <tbody class="table-font">
                            <tr v-for="(item, index) in useledger" :key="item.id">
                                <td>{{ index + 1 }}</td>
                                <td>{{ getCustomerName(item.customerid) }}</td>
                                <td>{{ item.transactiondate }}</td>
                                <td>{{ item.balance }}</td>
                                <td>
                                    <button class="new-btn" @click="detail(item.customerid)"> Detail</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- <div class="col-md-12 mt-20" >
                            <nav aria-label="Page navigation example" style="text-align: right !important">
                                <paginate style="margin: 0px !important; float: right" :page-count="noofpages"
                                    :click-handler="clickCallback" :prev-text="'&laquo;'" :next-text="'&raquo;'"
                                    :container-class="'pagination'">
                                </paginate>
                            </nav>
                        </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import { mapGetters } from 'vuex'
import axios from 'axios'
// import Paginate from "vuejs-paginate";
import moment from 'moment';
import Constants from '../../components/utilities/Constants.vue';
export default {
    // components: {    Paginate },
    mixins: [Constants],
    // props: ['id'],
    data() {
        return {
            currentpage: 1,
            noofrec: 10,
            useledger: [],
            alluser: [],

            moment: moment,
            // fromdate: moment().format('YYYY-MM-DD'),
            // todate: moment().format('YYYY-MM-DD'),



        }
    },
    computed: {
        // noofpages() {
        //     return Math.ceil(parseInt(this.rowcount) / parseInt(this.noofrec))
        // }
    },

    mounted() {
        this.refresh();
    },
    methods: {
        clickCallback(currentpage) {
            this.currentpage = currentpage
            this.$store.commit("assigncurrentpage", currentpage);
        },
        refresh() {
            let param = { isemployee: 1,fy:this.getCurrentFinancialYear()  }
            axios.post('api/users/ledger/fetch', param)
                .then(response => this.processResponseLedger(response.data))
                .catch(error => console.log(error))
        },
        processResponseLedger(data) {
            this.useledger = data?.ledger
            this.alluser = data?.alluser
        },
        getCustomerName(id) {
            let name = this.alluser.find(item => item.id == id)?.name
            return name || ''
        },
        detail(id) {
            this.$router.push(`/ledger-detail/${id}`)
        },
    }
}
</script>

<style scoped>
.btn-primary:hover {
    text-decoration: none !important;
    background-color: #3a476b !important;
}
</style>